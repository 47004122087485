import React from "react";
import Footer from "./navigation/Footer";
import Header from "./navigation/Header";
import Home from "./sections/Home";
import About from "./sections/About";
import SkillsSection from "./sections/SkillsSection";
import ContactForm from "./sections/ContactForm";
import Projects from "./sections/Projects";
import AddData from "./add-data/AddData";
import { SuperProvider } from "./SuperContext";

/**
 * Render all sections here.
 * 
 * @returns the App.js.
 */
function App() {

  return (
    <SuperProvider>
      <Header />
      <main>
        <Home />
        <About />
        <SkillsSection />
        <Projects />
        <ContactForm />
        {process.env.NODE_ENV === "development" ? <AddData /> : null}
      </main>
      <Footer />
    </SuperProvider>
  );
}

export default App;
