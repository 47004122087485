import { useState, useEffect, createContext } from "react";
import { correctRoute } from "./helpers";


const SuperContext = createContext();

const SuperProvider = ({ children }) => {
    const [skillTypes, setSkillTypes] = useState([]);
    const [skills, setSkills] = useState([]);
    const [techProjects, setTechProjects] = useState([]);
    const [projectSkills, setProjectSkills] = useState([]);

    useEffect(() => {
        fetch(correctRoute("skill_types"))
            .then((response) => response.json())
            .then((data) => setSkillTypes(data));
    }, []);

    useEffect(() => {
        fetch(correctRoute("skills"))
            .then((response) => response.json())
            .then((data) => setSkills(data));
    }, []);

    useEffect(() => {
        fetch(correctRoute("tech_projects"))
            .then((response) => response.json())
            .then((data) => setTechProjects(data));
    }, []);

    useEffect(() => {
        fetch(correctRoute("project_skills"))
            .then((response) => response.json())
            .then((data) => setProjectSkills(data));
    }, []);

    // console.log("Skill Types", skillTypes);
    // console.log("Skills", skills);
    // console.log("Tech Projects", techProjects);
    // console.log("Project Skills", projectSkills);

    return (
        <SuperContext.Provider value={{
            skillTypes,
            setSkillTypes,
            skills,
            setSkills,
            techProjects,
            setTechProjects,
            projectSkills,
            setProjectSkills
        }}>
            {children}
        </SuperContext.Provider>
    );
};

export { SuperContext, SuperProvider }